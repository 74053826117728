@import '../../resources';
.login {
  background: #fff;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  padding-bottom: 50px;
  .center {
    width: 100%;
    max-width: 1024px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 5px 3px 20px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
    @include Roboto;
    @include mobile-600 {
      flex-wrap: wrap-reverse;
    }
    .left {
      width: 50%;
      @include mobile-600 {
        width: 100%;
      }
      .card {
        box-shadow: 5px 3px 14px 0px rgba(0, 0, 0, 0.2);
        background-color: #fff;
        height: 100%;
        text-align: center;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
          color: #f58634;
          font-weight: 500;
        }
        h1 {
          color: #f58634;
          font-weight: 700;
          font-size: 25px;
          padding-bottom: 30px;
        }
        h5 {
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 40px;
          width: 100%;
          max-width: 300px;
          line-height: 25px;
        }
        .det {
          border-top: 1px solid #c2bfbf42;
          border-bottom: 1px solid #c2bfbf42;
          padding: 15px 0;
          p {
            padding-bottom: 10px;
          }
        }
      }
    }
    .right {
      width: 45%;
      @include mobile-600 {
        width: 100%;
        margin-bottom: 40px;
      }
      .log {
        text-align: center;
        margin-bottom: 30px;
        img {
          width: 70px;
        }
      }
      .form {
        .form_head {
          text-align: center;
          display: flex;
          justify-content: center;
          margin-bottom: 10px;
          .cav {
            border-bottom: 5px solid #f58634;
            h1 {
              font-weight: 600;
            }
          }
        }
        .form_body {
          .card {
            padding-bottom: 21px;
            input {
              background: #f1f5fd;
              border: 1px solid #d9d9d9;
              border-radius: 6px;
              padding: 17px;
              width: calc(100% - 34px);
              &::placeholder {
                @include Roboto;
                font-weight: 300;
                font-size: 14px;
              }
            }
          }
        }
        .form_bottom {
          text-align: center;
          button {
            background-color: #f58634;
            color: #fff;
            padding: 12px 20px;
            border: none;
            &:nth-child(1) {
              margin-right: 10px;
            }
          }
        }
        .loaderx {
          text-align: center;
          img {
            width: 100px;
          }
        }
      }
    }
  }
}
