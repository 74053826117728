@import '../../../../resources';
.taxtransaction {
  padding: 0 50px;
  margin-bottom: 50px;
  position: relative;
  @include mobile-1024 {
    padding: 0 20px;
  }
  .home_button {
    margin-bottom: 19px;
    button {
      @include Gilroy-Regular;
      padding: 12px 23px;
      background: #f1f5fd;
      border: 1px solid #afafaf;
      border-radius: 4px;
      font-size: 14px;
      margin-right: 19px;
      margin-bottom: 20px;
    }
  }
  .search_top {
    background-color: #fff;
    width: 100%;
    box-shadow: 0px 15px 41px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 40px;
    .form {
      width: 100%;
      display: flex;
      justify-content: space-between;
      background: #f7f9fe;
      border: 1px solid #c8c8c8;
      border-radius: 11px;
      padding: 10px 10px 10px 30px;
      input {
        width: 90%;
        border: none;
        border-left: 1px solid #535353;
        background: none;
        padding: 6px 10px;
        outline: none;
      }
      button {
        width: 5%;
        background: none;
        border: none;
        svg {
          font-size: 20px;
        }
      }
    }
  }
  .edit {
    padding: 20px 0;
    button {
      padding: 10px;
      background-color: $main-color;
      border: none;
      border-radius: 11px;
      color: white;
    }
  }
  .edit-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 21;
    height: 100vh;
    width: 100%;
    background: #00000082;
    justify-content: center;
    align-items: center;
    display: flex;
    .modal-body {
      background-color: #fff;
      width: 100%;
      max-width: 500px;
      border-radius: 7px;
      padding: 15px;
      overflow-y: auto;
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 0.5px solid #e1e1e1;
        padding-bottom: 10px;
        margin-bottom: 20px;
        h1 {
          font-size: 20px;
          @include Gilroy-Bold;
        }
        svg {
          font-size: 20px;
          cursor: pointer;
        }
      }
      .form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include Roboto;
        .card {
          width: 48%;
          margin-bottom: 20px;
          @include mobile-600 {
            width: 100%;
          }
          h6 {
            color: red;
          }
          p {
            font-weight: 400;
            font-size: 14px;
            padding-bottom: 5px;
          }
          select,
          input,
          textarea {
            border: 1px solid #b4b4b4;
            border-radius: 4px;
            width: 100%;
            padding: 10px 20px;
            resize: none;
            &::placeholder {
              @include Roboto;
            }
          }
          &.long {
            width: 100%;
          }
          .inputs {
            display: flex;
            align-items: center;
            button {
              margin-left: 40px;
              padding: 5px 5px;
              background: none;
              border: 1px solid #ff8a00;
              border-radius: 5px;
              color: #ff8a00;
              font-size: 11px;
            }
          }
          .showAdded {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0;
          }
        }
        .buttons {
          text-align: center;
          width: 100%;
          button,
          .button {
            margin-left: 20px;
            @include Gilroy-Regular;
            cursor: pointer;
            font-size: 14px;
            border: none;
            background: none;
            &.save {
              padding: 8px 16px;
              background: #ff8a00;
              border-radius: 21px;
              color: #fff;
            }
          }
        }
      }
    }
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    .card {
      width: 32%;
      background-color: #fff;
      border-radius: 6px;
      padding: 6px;
      margin-bottom: 10px;
      cursor: pointer;
      p {
        font-size: 12px;
        padding-bottom: 5px;
        color: #535353;
        @include Gilroy-Regular;
        b {
          color: #000;
        }
      }
    }
  }
  .flexs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .search_top {
      width: 45%;
      @include mobile-900 {
        width: 100%;
      }
    }
    .date-filter {
      background-color: #fff;
      width: 53%;
      box-shadow: 0px 15px 41px rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      padding: 30px;
      margin-bottom: 40px;
      @include mobile-900 {
        width: 100%;
      }
      .date-filter-cover {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include mobile-550 {
          flex-wrap: wrap;
          text-align: center;
          p {
            width: 100%;
            padding: 10px 0;
          }
        }
        .card {
          @include mobile-550 {
            width: 100%;
          }
        }
        .submit {
          @include mobile-550 {
            width: 100%;
            text-align: center;
            padding-top: 20px;
            button {
              width: 100%;
            }
          }
        }
        input {
          width: 160px;
          background: #f7f9fe;
          border: 1px solid #c8c8c8;
          border-radius: 11px;
          outline: none;
          height: 50px;
          padding: 0 10px;
          @include mobile-550 {
            width: 100%;
          }
        }
        button {
          height: 50px;
          padding: 0 10px;
          background-color: $main-color;
          border: none;
          border-radius: 11px;
        }
      }
    }
  }
  .table {
    overflow-x: auto;
    table {
      width: 100%;
      text-align: left;
      thead {
        tr {
          background-color: #dbe2ef;
          border-radius: 4px;
          th {
            padding: 10px;
            @include Roboto;
            font-weight: 400;
            font-size: 16px;
          }
        }
      }
      tbody {
        tr {
          background-color: #fff;
          border-radius: 4px;
          cursor: pointer;
          td {
            padding: 10px;
            @include Gilroy-Regular;
            font-weight: 400;
            font-size: 14px;
            text-transform: capitalize;
          }
          &.blur {
            color: transparent;
            text-shadow: 0 0 8px #000;
            cursor: pointer;
          }
        }
      }
    }
  }
  .pagination {
    padding-top: 30px;
    display: flex;
    justify-content: right;
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        @include Roboto;
        font-size: 12px;
        font-weight: bold;
        list-style: none;
        padding: 6px 10px;
        border: 1px solid #000;
        margin-right: 5px;
        cursor: pointer;
        &:hover {
          background-color: #fff;
          border: none;
          border: 1px solid #535353;
          color: #535353;
        }
        &.active {
          background-color: #fff;
          border: none;
          border: 1px solid #535353;
          color: #535353;
        }
      }
    }
  }
}
