@import '../../../../resources';
.LinkData {
  background: #fff;
  width: 100%;
  max-width: 800px;
  border-radius: 7px;
  height: max-content;
  position: relative;
  .linkdata-body {
    padding: 25px 30px;
    .top {
      padding-bottom: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e9e9e9;
      margin-bottom: 30px;
      h2 {
        @include Gilroy-Bold;
        font-size: 20px;
        letter-spacing: 0.02em;
        word-break: break-all;
      }
      .close {
        display: flex;
        align-items: center;
      }
      svg {
        font-size: 20px;
        cursor: pointer;
      }
      p {
        border-radius: 13px;
        font-size: 12px;
        @include Gilroy-Regular;
        padding: 4px 12px;
        border: none;
        background: rgba(255, 0, 0, 0.1);
        color: #ff0000;
        margin-right: 10px;
        @include mobile-550 {
          display: none;
        }
      }
      button {
        display: flex;
        align-items: center;
        color: #fff;
        background: #ff8a00;
        border-radius: 21px;
        border: none;
        padding: 15px 30px;
        @include mobile-420 {
          padding: 15px 20px;
        }
        @include Gilroy-Regular;
        font-size: 14px;
        font-weight: 500;
        margin-right: 50px;
        @include mobile-550 {
          display: none;
        }
      }
      .bg {
        .hr {
          display: flex;
        }
        p {
          display: none;
          @include mobile-550 {
            display: flex;
            align-items: center;
          }
        }
        button {
          display: none;
          @include mobile-550 {
            display: block;
          }
        }
      }
    }
    .data-body {
      .row {
        padding-bottom: 19px;
        margin-bottom: 19px;
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #e9e9e9;
        @include mobile-550 {
          justify-content: space-between;
        }
        p {
          @include Gilroy-Light;
          font-size: 12px;
          color: #000000;
          padding-bottom: 4px;
        }
        h2 {
          @include Gilroy-Medium;
          font-size: 18px;
          color: #000000;
        }
        .card {
          width: 30%;
          flex: 0 0 30%;

          &.X {
            width: 100%;
            flex: unset;
          }
          &.Y {
            width: 45%;
            flex: 0 0 45%;
            @include mobile-550 {
              width: 100%;
              flex: unset;
              margin-bottom: 10px;
            }
          }
          @include mobile-550 {
            width: 100%;
            flex: unset;
            margin-bottom: 10px;
          }
        }
        &.dif {
          cursor: pointer;
          p {
            @include Gilroy-Medium;
            font-size: 16px;
            color: #000000;
          }
        }
      }
    }
    .search {
      text-align: center;
      input {
        width: 100%;
        height: 62px;
        background: #ebeff5;
        border: 0.5px solid #c2c2c2;
        border-radius: 7px;
        font-family: 'Gilroy-Regular';
        font-size: 16px;
        padding: 0 20px;
      }
      .buttons {
        text-align: center;
        padding-top: 30px;
        img {
          width: 70px;
        }
        button {
          background: #ff8a00;
          border-radius: 21px;
          padding: 10px 20px;
          color: #fff;
          border: none;
        }
      }
    }
  }
  .modals {
    position: absolute;
    background-color: #00000031;
    height: 100%;
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal-c {
      padding: 19px 33px;
      background-color: #fff;
      height: fit-content;
      width: fit-content;
      max-width: 314px;
      box-shadow: 0px 19px 29px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      text-align: center;
      h2 {
        @include Gilroy-Bold;
        font-size: 16px;
        color: #000000;
        padding-bottom: 3px;
      }
      p {
        @include Gilroy-Light;
        font-size: 14px;
        padding-bottom: 20px;
      }
      svg {
        font-size: 93px;
        color: #39bb46;
        margin-top: 30px;
        margin-bottom: 14px;
      }
      h1 {
        @include Gilroy-Bold;
        font-size: 24px;
      }
      img {
        width: 50px;
      }
      .buttons {
        button {
          padding: 18px;
          border-radius: 11px;
          @include Gilroy-Regular;
          font-size: 14px;
          &:nth-child(1) {
            color: #434343;
            border: 0.5px solid #434343;
            margin-right: 10px;
            background: none;
          }
          &:nth-child(2) {
            color: #fff;
            border: none;
            background-color: #ff8a00;
            border: 0.5px solid #ff8a00;
          }
          &.sd {
            color: #fff;
            border: none;
            padding: 13px 26px;
            border-radius: 21px;
            background-color: #ff8a00;
            border: 0.5px solid #ff8a00;
          }
        }
      }
    }
  }
}
